

import React from 'react';
import '../assets/css/Dashboard.css'; // For styling
import MoreArticles from '../components/MoreArticles'
import ArticlePage from '../components/ArticlePage';
import ExclusiveArticles from '../components/ExclusiveArticles';
import TrendyMagazineLayout from '../components/TrendyMagazineLayout';
import ReadByCategory from '../components/ReadByCategory'
import Breaking from '../components/Breaking';
import NewsLayout from '../components/NewsLayout';
import Footer from '../components/Footer';
import Addvertisement from '../components/Advertisement';

const Dashboard = () => {
 
  return (
    <>
       <div style={{marginTop:'120px'}}></div>
       {/* <Breaking/> */}
       <Addvertisement/>
       <NewsLayout/>
        <TrendyMagazineLayout/>
        <ReadByCategory />
        <MoreArticles />
        <ExclusiveArticles/>
        <Footer/>
    </>
  );
};

export default Dashboard;

