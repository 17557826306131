import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Footer.css'; 

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <h5>QUICK LINKS</h5>
                        <a href="#">About</a>
                        <a href="#">Advertise</a>
                        <a href="#">Authors</a>
                        <a href="#">Contact Us</a>
                        <a href="#">Contribute</a>
                    </div>
                    <div className="col-md-3">
                        <h5>TOP CATEGORIES</h5>
                        <a href="#">Industry News</a>
                        <a href="#">Campaign Spot</a>
                        <a href="#">Campaign Feature</a>
                        <a href="#">Campaign Face Off</a>
                        <a href="#">Campaign Reviews</a>
                        <a href="#">Topical Spot</a>
                    </div>
                    <div className="col-md-3">
                        <h5>LATEST STORIES</h5>
                        <ul className="list-unstyled">
                            <li><a href="#">Independence Day campaigns over the years: Celebrating Unity, Freedom & Culture</a></li>
                            <li><a href="#">Indian Government to develop a unified system for misleading ad complaints</a></li>
                            <li><a href="#">Himalaya PartySmart ropes in Aditya Roy Kapur as its brand ambassador</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-3">
                        <ul>
                            <li><a href="#">Experts Call For A Fresh Approach In Independence Day Campaigns</a></li>
                            <li><a href="#">Roommates Or LFFs? Tide’s Superior Clean Bran Brings Gen Z Closer</a></li>
                            <li><a href="#">EssenceMediacom Wins The Integrated Media Mandate For Bambino Pasta Foods Industries Private Ltd.</a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>© 2024 Vi Finder </p>
                    {/* <div className="social-icons">
                        <a href="#"><i className="bi bi-facebook"></i></a>
                        <a href="#"><i className="bi bi-instagram"></i></a>
                        <a href="#"><i className="bi bi-twitter"></i></a>
                    </div> */}
                </div>
            </div>
        </footer>
    );
};

export default Footer;
