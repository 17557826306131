import React from 'react';
import '../assets/css/ViewfinderProduction.css';
import useApiRequest from './ApiComponent';



const ViewfinderProduction = () => {
  const { response: response1, error: error1 } = useApiRequest('GET', '/api/get_Tilessection');
  const { response: response2, error: error2 } = useApiRequest('GET', '/api/get_Aboutsectionmid');
  const url = localStorage.getItem('url');
  const Dataview = response1?.['Tiels List'];
  const Dataview2 = response2?.['Aboutsection List'];
  if (!Dataview||!Dataview2) {
      return <div class="loader"></div>
  }
 
  return (
    <>
      <div className="container text-center mt-5">
        <div className='prod-head'>
          <h1 className='prod-head-text'>{Dataview2[0]?.heading}</h1>
          <p className='prod-text-p'>
            {Dataview2[0]?.aboutdetail}
          </p>
        </div>
      </div>
      <div className='card-prod-container'>
        <div className='container'>
        <div className="row mt-5">
          {Dataview.map((service) => (
            <div key={service.id} className="col-md-3 card-prod"  style={{backgroundColor:"#EFF2F4"}}>
              <div className="service-box">
                <img src={`${url}/public/image/${service.image}`} alt={service.detail} style={{backgroundColor:"#fff"}}/>
                <h5 style={{backgroundColor:"#fff"}}>{service.detail}</h5>
              </div>
            </div>
          ))}
        </div>
        </div>
      </div>
    </>
  );
};

export default ViewfinderProduction;
