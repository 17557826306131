import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Subscribe.css';

const Subscribe = () => {
  const [email, setEmail] = useState('');

  // Function to handle input changes
  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    console.log('Subscribed Email:', email);
    
    const payload = {
      subscribeemail: email
    };
    
    try {
      const response = await fetch('https://viewfinder.concentics.in/api/get_customerdata', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();
      console.log("Subscribe result:", result);
      
      if (response.ok) {
        alert('Subscribed successfully');
        // Reset the email field
        setEmail('');
      } else {
        alert('Error: ' + result.message);
      }
    } catch (error) {
      console.error('Error submitting subscription:', error);
      alert('Failed to subscribe.');
    }
  };

  return (
    <section className="subscribe-section" style={{ padding: '30px', backgroundColor: '#EEEEEE' }}>
      <div className="container" style={{ backgroundColor: "#EEEEEE" }}>
        <div className='subscribe-container'>
          <h2 className='subscribe-head'>Subscribe</h2>
          <p className='subscribe-p'>Subscribe to stay tuned for new news and latest updates. Let's do it!</p>
          <form className="form-inline justify-content-center" style={{ backgroundColor: '#EEEEEE' }} onSubmit={handleSubmit}>
            <input
              type="email"
              className="input-subscribe"
              placeholder="Enter your email address"
              value={email}
              onChange={handleInputChange}
              required // This ensures the field is required
            />
            <button type="submit" className="subscribe-btn">
              Subscribe
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
