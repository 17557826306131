import React from 'react';
import { useState, useEffect } from 'react';
import '../assets/css/NewsLayout.css';
import useApiRequest from './ApiComponent';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

const NewsLayout = () => {
    const { response: response1, error: error1 } = useApiRequest('GET', '/api/get_Magazinarticles');
    const navigate = useNavigate();
    const url = localStorage.getItem('url');
    const Dataview = response1?.['magazinarticles List'];
    const [latest, setLatest] = useState(null);
    const [oldLatest, setOldLatest] = useState(null);
    const [breaking,setBreaking] = useState(null)
    dayjs.extend(relativeTime);
    useEffect(() => {
        if (response1) {
            const Dataview = response1['magazinarticles List'];

            if (Dataview) {
                const existingArticles = [];
                const newTrendingArticles = Dataview.filter(article =>article.active == '1'&&
                    article.tranding == '1' && isNewArticle(article.date, existingArticles)
                );

                // Sort the newTrendingArticles by date in descending order
                newTrendingArticles.sort((a, b) => new Date(b.date) - new Date(a.date));

                // Get the latest three trending articles
                const latestThreeTrendingArticles = newTrendingArticles.slice(0, 3);

                // Set state variables for latest, oldLatest, and oldOldLatest
                setLatest(latestThreeTrendingArticles[0] || null);
                setOldLatest(latestThreeTrendingArticles || null);

                console.log("Latest Three Trending Articles:", latestThreeTrendingArticles);
            }
            if (Dataview) {
                const existingArticles = [];
                const newTrendingArticles = Dataview.filter(article =>article.active === '1'&&
                    article.breaking === '1' && isNewArticle(article.date, existingArticles)
                );

                // Sort the newTrendingArticles by date in descending order
                newTrendingArticles.sort((a, b) => new Date(b.date) - new Date(a.date));

                // Get the latest three trending articles
                const latestThreeTrendingArticles = newTrendingArticles;
                const updatedArticles = latestThreeTrendingArticles.map(article => {
                    // Calculate "ago" times
                    const createdAgo = dayjs(article.created_at).fromNow();
                    const updatedAgo = dayjs(article.updated_at).fromNow();
                  
                    // Return the article with the new fields
                    return {
                      ...article,
                      createdAgo: createdAgo,
                      updatedAgo: updatedAgo,
                    };
                  });
                // Set state variables for latest, oldLatest, and oldOldLatest
                setBreaking(updatedArticles || null);
                console.log("latestThreeTrendingArticlesbreaking ",updatedArticles)
            }
        }
    }, [response1]);
    
    console.log("latest ", latest)
    function isNewArticle(articleDate, existingArticles) {
        const articleDateObj = new Date(articleDate);
        return !existingArticles.some(existingArticle => {
            const existingDateObj = new Date(existingArticle.date);
            return articleDateObj <= existingDateObj;
        });
    }


    const handledetialsPage = (article) => {
        navigate('/ArticleDetails', { state: { article } });
      }
      if(!response1){
        return <div class="loader"></div>
      }

    return (
        <div className="container">
            <div className="row">
                {latest && (<div className="col-md-6 px-0" onClick={()=>{handledetialsPage(latest)}} style={{cursor:'pointer'}}>
                    <div className="news-content px-0">
                        <div className="trending">TRENDING</div>
                        <img src={`${url}/public/image/${latest.image}`} className="img-fluid" alt="Trending News" />
                        <h2 className='Dashborad-Title'>{latest.heading}</h2>
                        <p>{latest.shortdes }</p>
                    </div>
                </div>)}
                <div className="col-md-3 px-0">
                    {oldLatest&&oldLatest.slice(1).map((item, index) => (
                        <div className="news-content " key={index} onClick={()=>{handledetialsPage(item)}} style={{cursor:'pointer'}}>
                            <img src={`${url}/public/image/${item.image}`} className="img-fluid" alt="News" />
                            <h5>{item.heading}</h5>
                        </div>
                    ))}
                </div>
                <div className="col-lg-3 px-0 h-100" style={{ marginTop: '1.3rem' }}>
                    <div className="">
                        <div className="breaking">BREAKING</div>
                        <div className="breaking-news-container mt-2">
                            <div className="breaking-news-content" id="breaking-news-content">
                                {breaking&&breaking.map((item, index) => (
                                    <div className="breaking-news-item" key={index} style={{cursor:'pointer'}} onClick={()=>{handledetialsPage(item)}}>
                                        <h6 className="mt-0 mb-1 Dashborad-Title">{item.heading}</h6>
                                        <small className="text-muted">{item.updatedAgo}</small>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsLayout;
