import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/VisionSection.css'; // Create this CSS file to include your styles

const Vision = () => {

    const img = require('../assets/images/vision.jpg')
    return (
        <div className=" mt-5" className='vis-Sec'>
            <div className="row vision-section gx-0">
                <div className="col-md-6 " style={{backgroundColor:'#EEEEEE'}}>
                    <div className='vision-text' >
                    <h2 style={{backgroundColor:'#EEEEEE',marginLeft:'20px'}} className='vis-head'>OUR VISION</h2>
                    <p style={{backgroundColor:'#EEEEEE',marginLeft:'20px',fontWeight:'500'}} className='vis-p'>We are committed to showcasing the journeys of successful individuals while also providing a platform for beginners with exceptional potential. Through in-depth cover stories, exclusive interviews, and inspiring features, V-finder Magazine aims to foster a community where passion meets opportunity.</p>
                    <p className="pt-4"  className='vis-p2' style={{backgroundColor:'#EEEEEE',marginLeft:'20px',fontWeight:'500'}}>Join us as we embark on this journey of discovery and inspiration, celebrating those who are making their mark and those who are destined to do so. Welcome to V-finder Magazine, where visionaries find their voice.</p>
                    </div>
                </div>
                <div className="col-md-6 vision-img" style={{backgroundImage: `url(${img})`, backgroundSize: '100% 100%'}}>
                    <div className='' style={{
                        background: 'linear-gradient(270deg, rgba(236, 228, 228, 0), rgb(238 238 238))',
                        width: '90%',
                        height: '100%'
                    }}></div>
                </div>
            </div>
        </div>
    );
}

export default Vision;
