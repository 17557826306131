import React from 'react';
import '../assets/css/ReadByCategory.css';
import useApiRequest from './ApiComponent';
import { Link, useNavigate } from 'react-router-dom';    

function ReadByCategory() {
    const { response, error } = useApiRequest('GET', '/api/get_Category');
    const navigate = useNavigate(); // Initialize navigate function
console.log("response cat ",response)
    const handlenavigate = (category) => {
        navigate(`/${category.replace(/\s+/g, '')}`); // Navigate to the category page
    }

  

    // Filter categories to show only those with active === 1
    const activeCategories = response?.['Category List']?.filter(category => category.active === '1');
    if(!activeCategories){
        return <div class="loader"></div>
      }

    return (
        <div className="container">
            <div className='Read-text'>
                <h1>READ BY CATEGORY</h1>
            </div>
            <div className="categories">
                {activeCategories?.map((category, index) => (
                    <div 
                        className="category" 
                        style={{ backgroundColor: '#fff', cursor: 'pointer' }} 
                        onClick={() => handlenavigate(category.category)} 
                        key={category.id}
                    >
                        <h2 style={{ backgroundColor: '#fff' }}>
                            <Link 
                                to={`/${category.category.replace(/\s+/g, '')}`} 
                                className='Link-text' 
                                style={{ backgroundColor: '#fff' }}
                            >
                                {category.category}
                            </Link>
                        </h2>
                        <span className="underline"></span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ReadByCategory;
