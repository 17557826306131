import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import '../assets/css/Dashboard.css'; // For styling
import MoreArticles from '../components/MoreArticles';
import ArticlePage from '../components/ArticlePage';
import ExclusiveArticles from '../components/ExclusiveArticles';
import Subscribe from '../components/Subscribe';
import Footer from '../components/Footer';

const ArticleDetails = () => {
  const location = useLocation(); // Get the location object
  const { article } = location.state || {}; // Retrieve the passed article data

  console.log("Article data: ", article); // Log the article data for debugging

  useEffect(() => {
    // Scroll to the top of the page when the article data changes
    window.scrollTo(0, 0);
  }, [article]);

  return (
    <>
      <div className='dashboard-container' style={{ marginTop: '120px' }}>
        {/* Pass the article details to ArticlePage */}
        <ArticlePage details={article} />
        <MoreArticles />
        <ExclusiveArticles />
        <Footer/>
      </div>
    </>
  );
};

export default ArticleDetails;
