import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../assets/css/ArticleList.css';
import { useNavigate, Link } from 'react-router-dom';
import useApiRequest from './ApiComponent';

const ArticleList = ({ categoryName }) => {
    const { response: response1, error: error1 } = useApiRequest('GET', '/api/get_Category');
    const { response: response2, error: error2 } = useApiRequest('GET', '/api/get_Magazinarticles');
    const navigate = useNavigate();
    const url = localStorage.getItem('url');
    const Dataview = response2?.['magazinarticles List'];
    const [searchTerm, setSearchTerm] = useState('');

    const filteredArticles = Dataview?.filter(article => 
        article.status === "Article" && 
        article.active === 1 && 
        categoryName === article.category &&
        article.heading.toLowerCase().includes(searchTerm.toLowerCase()) // Filter by search term
    );

    const handleReadMore = (article) => {
        navigate('/ArticleDetails', { state: { article } });
    };

    const handlenavigate = (category) => {
        navigate(`/${category.replace(/\s+/g, '')}`);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredCategories = response1?.['Category List']?.filter(category =>
        category.category.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (!Dataview || !filteredArticles || filteredArticles.length === 0) {
        return (
            <div className="container">
                {/* <h4>No articles found for this category.</h4> */}
            </div>
        );
    }

    return (
        <div className="container">
            <div className="row">
                <div className='col-lg-8'>
                    <div className="row">
                        {filteredArticles.map((article, index) => (
                            <div key={index} className="col-md-6 mb-4">
                                <div className="card-article">
                                    <img src={`${url}/public/image/${article.image}`} className="card-img-top-art" alt="Article" />
                                    <div className="card-body-article">
                                        <div className="card-meta mb-2">
                                            <span className="card-date-article">
                                                <i className="far fa-eye" style={{ marginRight: '10px' }}></i>
                                                {article.date}
                                            </span>
                                        </div>
                                        <h5 className="card-title-article">{article.heading}</h5>
                                        <p className="card-text-p-art" style={{ marginBottom: '0px' }}>{article.shortdes}</p>
                                        <a 
                                            onClick={() => handleReadMore(article)} 
                                            className="read-article-btn" 
                                            style={{ textDecoration: 'none', cursor: 'pointer' }}
                                        >
                                            <span>Read Article <i className="fas fa-arrow-right"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="sidebar">
                        <div className='searchBox'>
                            <h5 className='search-text'>Search</h5>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search..."
                                    aria-label="Search"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                            </div>
                        </div>
                        <div className='Trending-container'>
                            <h4 className='Trending-text'>Blog Category</h4>
                            <hr />
                            <div className="trending-items-container">
                                {filteredCategories?.map((category) => (
                                    <div
                                        key={category.id}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handlenavigate(category.category)}
                                        className='category-title-art'
                                    >
                                        <span className='category-title-art-text'>
                                            <Link
                                                to={`/${category.category.replace(/\s+/g, '')}`}
                                                className='Link-text' style={{ backgroundColor: '#EEEEEE' }}
                                            >
                                                {category.category}
                                            </Link>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ArticleList;
