import React from 'react'
import ContactForm from '../components/ContactFrom'
import Footer from '../components/Footer'
import Addvertisement from '../components/Advertisement';

function Contactus() {
  return (
    <>
      <div style={{ marginTop: '120px' }}></div>
      <div className=''>
        <Addvertisement />
      </div>
      <ContactForm />
      <Footer />
    </>
  )
}

export default Contactus