import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/css/Header.css';
import useApiRequest from './ApiComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Offcanvas } from 'react-bootstrap';

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [isNavTabOpen, setIsNavTabOpen] = useState(false);
  const { response, error } = useApiRequest('GET', '/api/get_Category');
  const navigate = useNavigate();

  const toggleNavTab = () => {
    setIsNavTabOpen(!isNavOpen);
  }

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const modalhide = () => {
    setIsNavOpen(!isNavOpen);
  }

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);
  const navigateHome = () => {
    navigate('/');
  }

  return (
    <>
      <div>
        <div style={{ position: 'fixed', zIndex: '888', width: '100%', marginTop: '-20px', paddingTop: "0px", top: 20 }}>
          <div className="container d-flex justify-content-between align-items-center py-2">
            <div className="text-center flex-grow-1">
              <div className='mt-3' onClick={navigateHome} style={{ cursor: 'pointer' }}>
                <span className='logo-text'><span style={{ color: 'red' }}>V</span>-FINDER MAGAZINE</span><br />
                <span className='logo-text-sec'>V FOR VISIONARIES</span>
              </div>
            </div>
            <div className="d-block d-md-none">
              <FontAwesomeIcon 
                icon={faBars} 
                className="navbar-toggler-icon" 
                onClick={handleShow}  
              />
            </div>
          </div>
          <nav>
            <div className="navbar">
              <div className="container nav-container">
                <input
                  className="checkbox"
                  type="checkbox"
                  name=""
                  id="checkbox"
                  checked={isNavOpen}
                  onChange={toggleNav}
                />
                <div className="hamburger-lines" onClick={toggleNav}>
                  <span className="line line1"></span>
                  <span className="line line2"></span>
                  <span className="line line3"></span>
                </div>
                <div className={`main-menu ${isNavTabOpen ? 'open' : ''}`}>
                  <ul className="menu">
                    <li><Link to="/">Home</Link></li>
                    <li><a onClick={toggleNav}>Categories</a></li>
                    <li><Link to="/about">About Us</Link></li>
                    <li><Link to="/contact">Contact Us</Link></li>
                  </ul>
                </div>
                <div className={`menu-items ${isNavOpen ? 'open' : ''}`}>
                  <ul className='ul' style={{backgroundColor:'#FAF9F6'}}>
                    {response?.['Category List']
                      ?.filter(category => category.active === '1') // Filter categories where active == 1
                      .map(category => (
                        <React.Fragment key={category.id}>
                          <li><Link to={`/${category.category.replace(/\s+/g, '')}`} className='Link-text' onClick={modalhide}>{category.category}</Link></li>
                          <span className='underline-header'></span>
                        </React.Fragment>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="nav flex-column menu">
            <li className="nav-item">
              <Link to="/" className="nav-link" onClick={handleClose}>Home</Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link" onClick={handleClose}>Contact Us</Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-link" onClick={handleClose}>About Us</Link>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
      {error && <div>Error: {error}</div>}
    </>
  );
};

export default Header;
