import React from 'react';
import '../assets/css/Addvertisement.css'; // Import CSS for additional styles
import useApiRequest from './ApiComponent';
const logoimage = require('../assets/images/Article.jpg'); // Import image properly

const Addvertisement = () => {
    const { response: response1, error: error1 } = useApiRequest('GET', '/api/get_Advertisment');
    
    const Addvertisement = response1?.['Advertisement List'];
    
    // Handle the case when there's no data yet
    if (!Addvertisement || Addvertisement.length === 0) {
            return <div class="loader"></div>
          
    }

    // Assuming 'advheading' contains HTML code
    const advertisementHtml = Addvertisement[0]?.advheading;

    return (
        <div className="banner-container">
            {/* Render the HTML content using dangerouslySetInnerHTML */}
            {advertisementHtml&&Addvertisement[0].active==1 && (
                <div 
                    className="advertisement-content" 
                    dangerouslySetInnerHTML={{ __html: advertisementHtml }}
                />
            )}
        </div>
    );
};

export default Addvertisement;
