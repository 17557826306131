import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/TrendyMagazineLayout.css';
import useApiRequest from './ApiComponent';
const Trends1 = require('../assets/images/Trends1.jpg');
const Trends2 = require('../assets/images/Trends2.png');
const Trends3 = require('../assets/images/Trends3.jpg');
const Trends4 = require('../assets/images/Trends4.jpg');
const Trends5 = require('../assets/images/art2.jpg');



const TrendyMagazineLayout = () => {
  const { response: response1, error: error1 } = useApiRequest('GET', '/api/get_Updatetarnds');
  const Dataview = response1?.['Update Trands List'];
  const url = localStorage.getItem('url');
  //console.log("Dataview ",Dataview)
  if (!Dataview) {
    return <div></div>;
  }
  const cardsLeft = [
    {
      imgSrc: `${url}/public/image/${Dataview[0].leftoneimage}`,
      title: Dataview[0].leftoneheading,
      text: Dataview[0].leftonedetail
    },
    {
      imgSrc: `${url}/public/image/${Dataview[0].leftsecimage}`,
      title: Dataview[0].leftsecheading,
      text: Dataview[0].rightonedetail
    }
  ];
  const cardsRight = [
    {
      imgSrc: `${url}/public/image/${Dataview[0].rightoneimage}`,
      title:  Dataview[0].rightoneheading,
      text: Dataview[0].rightonedetail
    },
    {
      imgSrc: `${url}/public/image/${Dataview[0].rightsecimage}`,
      title: Dataview[0].rightsecheading,
      text: Dataview[0].rightsecdetail
    }
  ];
  if(!cardsLeft){
    return <div class="loader"></div>
  }
  return (
    <>
      <div className='trendy-container mt-3'>
        <div className="container">
          <div className="text-center mb-4 mt-4 trends-head-text">
            <h2 style={{fontSize:'37px'}}>{Dataview[0].heading}</h2>
          </div>
          <div className="row g-3">
            <div className="col-md-3 mb-4 px-2">
              <div className="row g-3">
                {cardsLeft.map((card, index) => (
                  <div key={index} className="col-md-12 mb-4 custom-card-trend" >
                    <div className="card-trend">
                      <img src={card.imgSrc} className="card-img-top custom-img-trend" alt={card.title} />
                      <div className="text-left custom-body-trend">
                        <h5 className="card-title-trend " >{card.title}</h5>
                        <p className="card-text-trend" >{card.text}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6 mb-1 px-3" >
              <div className="card">
                <img src={`${url}/public/image/${Dataview[0].centerimage}`} className="" alt="Magazine" />
                {/* <div className="card-body text-center d-flex flex-column justify-content-center">
              <button className="btn btn-primary">Pre-Book Now</button>
            </div> */}
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="row g-3">
                {cardsRight.map((card, index) => (
                  <div key={index} className="col-md-12 mb-4 custom-card-trend">
                    <div className="card-trend">
                      <img src={card.imgSrc} className="card-img-top custom-img-trend" alt={card.title} />
                      <div className="text-right custom-body-trend">
                        <h5 className="card-title-trend">{card.title}</h5>
                        <p className="card-text-trend" >{card.text}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrendyMagazineLayout;
