import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/About.css'
import VisionSection from '../components/VisionSection';
import OverTeam from '../components/OurTeam'
import ViewfinderProduction from '../components/ViewfinderProduction';
import useApiRequest from '../components/ApiComponent';
import Subscribe from '../components/Subscribe';
import Footer from '../components/Footer';
import FiveOhFiveComponent from '../components/FiveOhFiveComponent';
import Addvertisement from '../components/Advertisement';

const VFinderMagazine = () => {

    const { response:response1, error:error1 } = useApiRequest('GET', '/api/get_Aboutheader');
  
    const url = localStorage.getItem('url');
    const trendingData = response1?.['Aboutheading List'];
    // console.log("trendingData ", trendingData)
    if (!trendingData) {
        return <div></div>;
    }
    const sideimg = require('../assets/images/newside.png')
    const sideimg1 = require('../assets/images/break.jpg')
    return (
        <>
            <div style={{ marginTop: '100px' }}></div>
            <div className='' style={{paddingTop:'20px'}}>
            <Addvertisement/>
            </div>
            <div className="pb-0 content-section">
                <div className="row">
                    <div className="col-md-8">
                        <h1 className=" main-title mt-4">
                            <div
                                dangerouslySetInnerHTML={{ __html: trendingData[0].headtitle }}
                            />
                        </h1>
                        <div className="left-image d-flex">
                            <img src={`${url}/public/image/${trendingData[0].leftimage}`} alt="Photographer and Videographer" className="img-fluid1" />
                            <div>
                                <p className="description-text">
                                   {trendingData[0].shortmessage}
                                </p>
                                <button className="btn btn-custom btn-lg">Book Consulting Call</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 pt-0 h-100">
                        <div >
                            <img src={`${url}/public/image/${trendingData[0].rightimage}`} alt="Content Creator" className="h-100 w-100" />
                        </div>
                    </div>
                </div>
            </div>
            <VisionSection/>
            {/* <OverTeam /> */}
            <ViewfinderProduction />
            <Subscribe />
            <Footer/>
        </>
    );
};

export default VFinderMagazine;
