import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  h

  body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(112, 234, 239, 0.13); 
    color: rgba(54, 120, 121, .67); 
  }
`;

const Pattern = styled.div`
  width: 100vmin;
  height: 50vmin;
  position: relative;

  &::after {
    content: '500';
    font-size: 50vmin;
    color: transparent;
    text-shadow:
      ${({ theme }) => `
        ${Array.from({ length: 250 }).map(() => `
          ${Math.random() * 0.1}vmin ${Math.random() * -0.1}vmin
          0 hsla(${Math.random() * (240 - 120) + 120}, 80%, 55%, .004)
        `).join(', ')}
      `}
  }
`;

const ErrorPageWithPattern = () => {
  return (
    <>
      <GlobalStyle />
      <Pattern />
      <p>That's An Error. That's All We Know.</p>
    </>
  );
};

export default ErrorPageWithPattern;
