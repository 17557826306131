import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import useApiRequest from './ApiComponent';
import '../assets/css/ContactForm.css';

const ContactForm = () => {
    const { response: response1, error: error1 } = useApiRequest('GET', '/api/get_Contactus');
    const { response: response2, error: error2 } = useApiRequest('GET', '/api/get_Socialmedia');
    const Dataview = response1?.['Contact List'];
    const DataviewLink = response2?.['Socialmedia List'];

    const socialIcons = {
        Facebook: 'fab fa-facebook',
        Instagram: 'fab fa-instagram',
        LinkedIn: 'fab fa-linkedin',
        Twitter: 'fab fa-twitter'
    };

    const getIconClass = (linkget) => {
        switch(linkget.toLowerCase()) {
            case 'facebook':
                return socialIcons.Facebook;
            case 'instagram':
                return socialIcons.Instagram;
            case 'linkedin':
            case 'linkdin':
                return socialIcons.LinkedIn;
            case 'twitter':
                return socialIcons.Twitter;
            default:
                return 'fas fa-link'; // Default icon if none match
        }
    };

    // Form state for capturing user input
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    // Function to handle form input changes
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
        console.log('Form Data:', formData);
        
        const m = {
            "conatctname": formData.name,
            "conatctemail": formData.email,
            "conatctphone": formData.phone,
            "conatctmessage": formData.message,
            "subscribeemail": ""
        }
        
        try {
            const response = await fetch('https://viewfinder.concentics.in/api/get_customerdata', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(m),
            });

            const result = await response.json();
            console.log("result ", result);
            if (response.ok) {
                alert('Form submitted successfully');
                // Reset form fields
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                });
            } else {
                alert('Error: ' + result.message);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Failed to submit the form.');
        }
    };

    if (!Dataview) {
        return <div className="loader"></div>;
    }

    return (
        <div className="container mt-2">
            <div className="row">
                <div className="col-md-5">
                    <div className="contact-info">
                        <h4 style={{ backgroundColor: '#f1f1f1' }}>CONTACT INFORMATION</h4>
                        <p><i className="fas fa-phone-alt icon-first"></i> {Dataview[0]?.number}</p>
                        <p><i className="fas fa-envelope icon"></i> {Dataview[0]?.email}</p>
                        <p><i className="fas fa-map-marker-alt icon"></i> {Dataview[0]?.location}</p>
                        <div style={{ marginTop: '100px' }}>
                            <div className="social-icons" style={{ backgroundColor: '#f1f1f1' }}>
                                {DataviewLink && DataviewLink.map((link, index) => (
                                    <a key={index} href={link.link} target="_blank" rel="noopener noreferrer">
                                        <i className={getIconClass(link.linkget)}></i>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-7">
                    <form className="form-container" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="tel"
                                className="form-control"
                                id="phone"
                                placeholder="Phone Number"
                                value={formData.phone}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id="message"
                                placeholder="Message"
                                value={formData.message}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="btn-container">
                            <button type="submit" className="submit-button">Send Message</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
